var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-item-group',{staticClass:"item-group"},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var toggle = ref.toggle;
return [_c('v-card',{class:{ card: true },staticStyle:{"min-width":"255px"},attrs:{"height":"150","width":"350"},on:{"click":toggle}},[_c('v-card-title',[_vm._v("Revenue")]),_c('v-card-subtitle',{staticStyle:{"font-size":"1rem"}},[_c('br'),_c('h1',{staticStyle:{"font-weight":"300"}},[_vm._v(" "+_vm._s(_vm.currency)+" "+_vm._s(_vm._f("numberWithCommas")(_vm.totalRevenue))+" "),(_vm.totalRevenue > _vm.previousTotalRevenue)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-arrow-up ")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-arrow-down")])],1)]),_c('v-card-actions',[_vm._v("(Last 30 days)")])],1)]}}])}),_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var toggle = ref.toggle;
return [_c('v-card',{class:{ card: true },attrs:{"height":"150","width":"350"},on:{"click":toggle}},[_c('v-card-title',[_vm._v("Upcoming Appts")]),_c('v-card-subtitle',{staticStyle:{"font-size":"1.1rem"}},[_c('br'),_c('h1',{staticStyle:{"font-weight":"300"}},[_vm._v(" "+_vm._s(_vm._f("numberWithCommas")(_vm.weekAppointmentCount))+" "),(_vm.weekAppointmentCount > _vm.appointmentCount)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-arrow-up ")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-arrow-down")])],1)]),_c('v-card-actions',[_vm._v(" ("+_vm._s(_vm.appointmentCount)+" last week) ")])],1)]}}])}),_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var toggle = ref.toggle;
return [_c('v-card',{class:{ card: true },attrs:{"height":"150","width":"350"},on:{"click":toggle}},[_c('v-card-title',[_vm._v("New Clients")]),_c('v-card-subtitle',{staticStyle:{"font-size":"1.1rem"}},[_c('br'),_c('h1',{staticStyle:{"font-weight":"300"}},[_vm._v(" "+_vm._s(_vm._f("numberWithCommas")(_vm.clientCount))+" "),(_vm.lastWeekClientCount < _vm.clientCount)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-arrow-up ")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-arrow-down")])],1)]),_c('v-card-actions',[_vm._v("("+_vm._s(_vm.lastWeekClientCount)+" last week)")])],1)]}}])}),_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var toggle = ref.toggle;
return [_c('v-card',{class:{ card: true },attrs:{"height":"150","width":"350"},on:{"click":toggle}},[_c('v-card-title',[_vm._v("App client rate")]),_c('v-card-subtitle',{staticStyle:{"font-size":"1.1rem"}},[_c('br'),_c('h1',{staticStyle:{"font-weight":"300"}},[_vm._v(" "+_vm._s(_vm.appClientRate)+" "),(_vm.currentMonthClientCount - _vm.lastMonthClientCount > 0)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-arrow-up ")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-arrow-down")])],1)]),_c('v-card-actions',[_vm._v("(Last 30 days)")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }