

























import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
import moment from "moment-timezone";
const tz = localStorage.getItem("tz") ?? "Africa/Nairobi";

import BarChart from "../chart/BarChart.vue";

import paymentStoreModule from "@/store/modules/payment";
import { Role } from "@/types";

const { mapActions: paymentActions, mapGetters: paymentGetters } =
  createNamespacedHelpers("REVENUE_AGGREGATE_");

export default Vue.extend<any, any, any, any>({
  components: { BarChart },
  name: "RevenueGraph",
  props: {
    periodOverride: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    period: "Day",
    days: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    months: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    years: [2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028],
    chartData: {
      labels: [] as string[] | number[],
      datasets: [
        {
          label: "This week",
          backgroundColor: "#F48203",
          data: [] as number[],
          barThickness: 15, // number (pixels) or 'flex'
          maxBarThickness: 15, // number (pixels)
          pointRadius: 0,
        },
      ],
    },
    chartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: true,
      },
      tooltips: {
        titleFontSize: 12,
        bodyFontSize: 12,
        callbacks: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          label(tooltipItem: any) {
            // Get the dataset label.
            // const label = data.datasets[tooltipItem.datasetIndex].label;

            // Format the y-axis value.
            const value = tooltipItem.yLabel;

            return `Kshs${value}`;
          },
        },
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              callback: (value: number) => {
                if (value / 1000 > 1) return value / 1000 + "k";
                return value;
              },
            },
            position: "right",
          },
        ],
      },
    },
  }),
  watch: {
    period: "getAggregate",
    periodOverride() {
      if (this.periodOverride) this.period = this.periodOverride;
    },
  },
  computed: {
    ...paymentGetters(["paymentAggregate"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  created() {
    if (this.role) this.getAggregate();
  },
  methods: {
    ...paymentActions(["fetchPaymentAggregate"]),
    getAggregate() {
      let params = "/aggregate";
      const period = this.period.toLowerCase() as
        | "year"
        | "month"
        | "week"
        | "day";
      if (period === "year") {
        params = `/aggregate?period=${period}`;
      } else if (period === "month") {
        const startDate = moment().startOf("year").toISOString();
        const endDate = moment().endOf("year").toISOString();
        params = `/aggregate?startDate=${startDate}&endDate=${endDate}&period=${period}`;
      } else if (period === "day") {
        const startDate = moment().startOf("week").toISOString();
        const endDate = moment().endOf("week").toISOString();
        params = `/aggregate?startDate=${startDate}&endDate=${endDate}&period=${period}`;
      }

      params += `&businessId=${this.role?.business._id}`;
      this.fetchPaymentAggregate(params).then((aggregate) => {
        if (aggregate) this.setChartData(period);
      });
    },
    setChartData(period: string) {
      if (period === "day") {
        const dayData = {
          Sun: 0,
          Mon: 0,
          Tue: 0,
          Wed: 0,
          Thu: 0,
          Fri: 0,
          Sat: 0,
        };

        this.paymentAggregate.map((a: { _id: number; amount: number }) => {
          const indx = this.days[a._id - 1] as "Sun" | "Mon";
          dayData[indx] = a.amount;
        });

        this.chartData = {
          labels: this.days,
          datasets: [
            {
              label: "This week",
              data: Object.values(dayData),
              backgroundColor: "#F48203",
              barThickness: 15, // number (pixels) or 'flex'
              maxBarThickness: 15, // number (pixels)
              pointRadius: 0,
            },
          ],
        };
        return;
      }

      if (period === "month") {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const data = {
          Jan: 0,
          Feb: 0,
          Mar: 0,
          Apr: 0,
          May: 0,
          Jun: 0,
          Jul: 0,
          Aug: 0,
          Sep: 0,
          Oct: 0,
          Nov: 0,
          Dec: 0,
        };

        this.paymentAggregate.map((a: { _id: number; amount: number }) => {
          const indx = this.months[+a._id - 1] as "Jan" | "Feb";
          data[indx] = a.amount;
        });

        this.chartData = {
          labels: Object.keys(data),
          datasets: [
            {
              label: "This year",
              data: Object.values(data),
              backgroundColor: "#F48203",
              barThickness: 15, // number (pixels) or 'flex'
              maxBarThickness: 15, // number (pixels)
              pointRadius: 0,
            },
          ],
        };
        return;
      }

      if (period === "year") {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const data: any = {};

        const labels = this.years.filter((y) => new Date().getFullYear() >= +y);

        labels.map((l) => {
          data[l] = 0;
        });

        this.paymentAggregate.map((a: { _id: string; amount: number }) => {
          data[a._id] = a.amount;
        });

        this.chartData = {
          labels: labels,
          datasets: [
            {
              label: "Over the Years",
              data: Object.values(data),
              backgroundColor: "#F48203",
              barThickness: 15, // number (pixels) or 'flex'
              maxBarThickness: 15, // number (pixels)
              pointRadius: 0,
            },
          ],
        };
        return;
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("REVENUE_AGGREGATE_")) {
      this.$store.registerModule("REVENUE_AGGREGATE_", paymentStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("REVENUE_AGGREGATE_");
  },
});
