







































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
import moment from "moment-timezone";
const tz = localStorage.getItem("tz") ?? "Africa/Nairobi";

import appointmentStoreModule from "@/store/modules/appointment";
import { Business, Role } from "@/types";

const { mapActions: appointmentActions, mapGetters: appointmentGetters } =
  createNamespacedHelpers("EMPLOYEE_APPOINTMENTS");

export default Vue.extend<any, any, any, any>({
  name: "EmployeeList",
  data: () => ({
    date: "Today",
  }),
  created() {
    this.fetchAppointments();
  },
  watch: {
    role: "fetchAppointments",
  },
  computed: {
    ...appointmentGetters(["appointmentPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...appointmentActions(["fetchAppointmentList"]),
    fetchAppointments() {
      if (this.role) {
        const startDate = moment().startOf("day").toISOString();
        const endDate = moment().endOf("day").toISOString();
        const bid = (this.role.business as Business)?._id;
        const params = `?businessId=${bid}&startDate=${startDate}&endDate=${endDate}&limit=5&status=confirmed`;
        this.fetchAppointmentList(params);
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("EMPLOYEE_APPOINTMENTS")) {
      this.$store.registerModule(
        "EMPLOYEE_APPOINTMENTS",
        appointmentStoreModule
      );
    }
  },
  beforeDestroy() {
    // this.$store.unregisterModule("EMPLOYEE_APPOINTMENTS");
  },
});
