
























































































import Vue from "vue";

import DashboardCards from "@/components/dashboard/DashboardCards.vue";
import ClientGraph from "@/components/dashboard/ClientGraph.vue";
import ClientScheduleList from "@/components/dashboard/ClientScheduleList.vue";
import EmployeeList from "@/components/dashboard/EmployeeList.vue";
// import FacebookRetentionRateChart from "@/components/dashboard/FacebookRetentionRateChart.vue";
// import EmailOpenRateChart from "@/components/dashboard/EmailOpenRateChart.vue";
import StaffRevenueChart from "@/components/dashboard/StaffRevenueChart.vue";
import RevenueGraph from "@/components/dashboard/RevenueGraph.vue";
// import StaffAttendanceRateGraph from "@/components/dashboard/StaffAttendanceRateGraph.vue";
import ProductSalesGraph from "@/components/dashboard/ProductSalesGraph.vue";
// import InstagramRetantionRateChart from "@/components/dashboard/InstagramRetantionRateChart.vue";
// import SmsOpenRateChart from "@/components/dashboard/SmsOpenRateChart.vue";
// import AgizoShopRevenueGraph from "@/components/dashboard/AgizoShopRevenueGraph.vue";
// import GoogleAnalytics from "@/components/dashboard/GoogleAnalytics.vue";
// import Feedback from "@/components/dashboard/Feedback.vue";
// import NewReturningClientRateGraph from "@/components/dashboard/NewReturningClientRateGraph.vue";
import ActionButtons from "@/components/ActionButtons.vue";

export default Vue.extend<any, any, any, any>({
  components: {
    DashboardCards,
    ClientGraph,
    ClientScheduleList,
    EmployeeList,
    // FacebookRetentionRateChart,
    // EmailOpenRateChart,
    StaffRevenueChart,
    RevenueGraph,
    //StaffAttendanceRateGraph,
    ProductSalesGraph,
    // InstagramRetantionRateChart,
    // SmsOpenRateChart,
    // AgizoShopRevenueGraph,
    // GoogleAnalytics,
    // Feedback,
    // NewReturningClientRateGraph,
    ActionButtons,
  },
  name: "Dashboard",
  data: () => ({
    period: "",
  }),
});
