
import Vue, { VueConstructor } from "vue";
import { Line, mixins } from "vue-chartjs";

const { reactiveProp } = mixins;

export default (Vue as VueConstructor<Vue & Line>).extend<any, any, any, any>({
  extends: Line,
  mixins: [reactiveProp],
  props: ["options", "chartData"],
  mounted() {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.chartData, this.options);
  },
});
