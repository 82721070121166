



















































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
import moment from "moment-timezone";
const tz = localStorage.getItem("tz") ?? "Africa/Nairobi";

import paymentStoreModule from "@/store/modules/payment";
import { Business, Employee, Role } from "@/types";

const { mapActions: paymentActions, mapGetters: paymentGetters } =
  createNamespacedHelpers("STAFF_REVENUE_AGGREGATE_");

export default Vue.extend<any, any, any, any>({
  name: "StaffRevenueChart",
  props: {
    periodOverride: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    period: "Year",
    date: new Date().toISOString(),
  }),
  computed: {
    ...paymentGetters(["paymentAggregate"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  watch: {
    period: "getAggregate",
    role: "getAggregate",
    periodOverride() {
      if (this.periodOverride) this.period = this.periodOverride;
    },
  },
  created() {
    // this.getAggregate();
  },
  methods: {
    ...paymentActions(["fetchPaymentAggregate"]),
    getPercentage(n: number) {
      const total = this.paymentAggregate
        .map((r: { _id: Employee; amount: number }) => r.amount)
        .reduce((a: number, b: number) => a + b, 0);

      return Math.floor((n / total) * 100);
    },
    getAggregate() {
      const businessId = (this.role?.business as Business)?._id;
      const period = this.period.toLowerCase() as "year" | "day" | "month";
      const startDate = moment().startOf(period).toISOString();
      const endDate = moment().endOf(period).toISOString();
      let params = `/aggregate/employee?startDate=${startDate}&endDate=${endDate}`;
      if (businessId) params += `&businessId=${businessId}`;
      this.fetchPaymentAggregate(params);
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("STAFF_REVENUE_AGGREGATE_")) {
      this.$store.registerModule(
        "STAFF_REVENUE_AGGREGATE_",
        paymentStoreModule
      );
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("STAFF_REVENUE_AGGREGATE_");
  },
});
