























import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
import moment from "moment-timezone";
const tz = localStorage.getItem("tz") ?? "Africa/Nairobi";

import LineChart from "../chart/LineChart.vue";

import clientStoreModule from "@/store/modules/client";
import { Business, Role } from "@/types";

const { mapActions: clientActions, mapGetters: clientGetters } =
  createNamespacedHelpers("CLIENTS_AGGREGATE");

const {
  mapActions: clientWeekAggregateActions,
  mapGetters: clientWeekAggregateGetters,
} = createNamespacedHelpers("CLIENTS_LAST_WEEK_AGGREGATE");

export default Vue.extend<any, any, any, any>({
  components: { LineChart },
  name: "ClientGraph",
  props: {
    periodOverride: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    period: "Day",
    days: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    months: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    years: [2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028],
    chartData: {
      labels: [] as number[] | string[],
      datasets: [
        {
          label: "Yesterday",
          backgroundColor: "#FDF3E9",
          data: [] as number[],
          pointRadius: 0,
          borderColor: "#F48203",
          pointStyle: "line",
        },
        {
          label: "Today",
          backgroundColor: "#FCFCFE",
          data: [] as number[],
          pointRadius: 0,
          borderColor: "#DFE0EA",
          pointStyle: "line",
        },
      ],
    },
    chartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: true,
        position: "top",
        align: "start",
        labels: {
          usePointStyle: true,
        },
      },
      tooltips: {
        titleFontSize: 20,
        bodyFontSize: 25,
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: false,
              callback: (value: number) => {
                if (value % 1 === 0) {
                  return value;
                }
              },
            },
            position: "right",
          },
        ],
      },
    },
  }),
  created() {
    this.getAggregate();
  },
  watch: {
    period: "getAggregate",
    role: "getAggregate",
    periodOverride() {
      if (this.periodOverride) this.period = this.periodOverride;
    },
  },
  computed: {
    ...clientGetters(["clientAggregate"]),
    ...clientWeekAggregateGetters({
      prevPeriodClientAggregate: "clientAggregate",
    }),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...clientActions(["fetchClientAggregate"]),
    ...clientWeekAggregateActions({
      fetchPrevPeriodClientAggregate: "fetchClientAggregate",
    }),

    getAggregate() {
      if (!this.role) return;
      let prevPeriodParams = "";
      const businessId = (this.role.business as Business)._id;
      let params = "";
      const period = this.period.toLowerCase() as
        | "year"
        | "month"
        | "week"
        | "day";
      if (period === "year") {
        params = `?period=${period}`;
        prevPeriodParams = params;
      } else if (period === "month") {
        const startDate = moment().startOf("year").toISOString();
        const endDate = moment().endOf("year").toISOString();
        params = `?startDate=${startDate}&endDate=${endDate}&period=${period}`;

        const prevStartDate = moment()
          .subtract(1, "year")
          .startOf("year")
          .toISOString();
        const prevEndDate = moment()
          .subtract(1, "year")
          .endOf("year")
          .toISOString();

        prevPeriodParams = `?startDate=${prevStartDate}&endDate=${prevEndDate}&period=${period}`;
      } else if (period === "day") {
        const startDate = moment().startOf("week").toISOString();
        const endDate = moment().endOf("week").toISOString();
        params = `?startDate=${startDate}&endDate=${endDate}&period=${period}`;

        const prevStartDate = moment()
          .subtract(1, "week")
          .startOf("week")
          .toISOString();
        const prevEndDate = moment()
          .subtract(1, "week")
          .endOf("week")
          .toISOString();

        prevPeriodParams = `?startDate=${prevStartDate}&endDate=${prevEndDate}&period=${period}`;
      }
      this.fetchClientAggregate(params + `&businessId=${businessId}`).then(
        (aggregate) => {
          if (aggregate) {
            this.fetchPrevPeriodClientAggregate(
              prevPeriodParams + `&businessId=${businessId}`
            ).then((prevAggregate) => {
              if (prevAggregate) this.setChartData(period);
            });
          }
        }
      );
    },
    setChartData(period: string) {
      if (period === "day") {
        const data = {
          Sun: 0,
          Mon: 0,
          Tue: 0,
          Wed: 0,
          Thu: 0,
          Fri: 0,
          Sat: 0,
        };

        this.clientAggregate.map((a: { _id: number; count: number }) => {
          const indx = this.days[a._id - 1] as "Sun" | "Mon";
          data[indx] = a.count;
        });

        const prevPeriodData = {
          Sun: 0,
          Mon: 0,
          Tue: 0,
          Wed: 0,
          Thu: 0,
          Fri: 0,
          Sat: 0,
        };

        this.prevPeriodClientAggregate.map(
          (a: { _id: number; count: number }) => {
            const indx = this.days[a._id - 1] as "Sun" | "Mon";
            prevPeriodData[indx] = a.count;
          }
        );

        this.chartData = {
          labels: this.days,
          datasets: [
            {
              label: "This week",
              backgroundColor: "#FDF3E9",
              data: Object.values(data),
              pointRadius: 0,
              borderColor: "#F48203",
              pointStyle: "line",
            },
            {
              label: "Last week",
              backgroundColor: "#FCFCFE",
              data: Object.values(prevPeriodData),
              pointRadius: 0,
              borderColor: "#DFE0EA",
              pointStyle: "line",
            },
          ],
        };
        return;
      }

      if (period === "month") {
        const data = {
          Jan: 0,
          Feb: 0,
          Mar: 0,
          Apr: 0,
          May: 0,
          Jun: 0,
          Jul: 0,
          Aug: 0,
          Sep: 0,
          Oct: 0,
          Nov: 0,
          Dec: 0,
        };

        this.clientAggregate.map((a: { _id: number; count: number }) => {
          const indx = this.months[+a._id - 1] as "Jan" | "Feb";
          data[indx] = a.count;
        });

        const prevPeriodData = {
          Jan: 0,
          Feb: 0,
          Mar: 0,
          Apr: 0,
          May: 0,
          Jun: 0,
          Jul: 0,
          Aug: 0,
          Sep: 0,
          Oct: 0,
          Nov: 0,
          Dec: 0,
        };

        this.prevPeriodClientAggregate.map(
          (a: { _id: number; count: number }) => {
            const indx = this.months[+a._id - 1] as "Jan" | "Feb";
            prevPeriodData[indx] = a.count;
          }
        );

        this.chartData = {
          labels: Object.keys(data),
          datasets: [
            {
              label: "This year",
              backgroundColor: "#FDF3E9",
              data: Object.values(data),
              pointRadius: 0,
              borderColor: "#F48203",
              pointStyle: "line",
            },
            {
              label: "Last year",
              backgroundColor: "#FCFCFE",
              data: Object.values(prevPeriodData),
              pointRadius: 0,
              borderColor: "#DFE0EA",
              pointStyle: "line",
            },
          ],
        };
        return;
      }

      if (period === "year") {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const data: any = {};

        const labels = this.years.filter((y) => new Date().getFullYear() >= +y);

        labels.map((l) => {
          data[l] = 0;
        });

        this.clientAggregate.map((a: { _id: string; count: number }) => {
          data[a._id] = a.count;
        });

        this.chartData = {
          labels: labels,
          datasets: [
            {
              label: "Over the Years",
              backgroundColor: "#FDF3E9",
              data: Object.values(data),
              pointRadius: 0,
              borderColor: "#F48203",
              pointStyle: "line",
            },
          ],
        };
        return;
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("CLIENTS_AGGREGATE")) {
      this.$store.registerModule("CLIENTS_AGGREGATE", clientStoreModule);
    }
    if (!this.$store.hasModule("CLIENTS_LAST_WEEK_AGGREGATE")) {
      this.$store.registerModule(
        "CLIENTS_LAST_WEEK_AGGREGATE",
        clientStoreModule
      );
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("CLIENTS_LAST_WEEK_AGGREGATE");
    this.$store.unregisterModule("CLIENTS_AGGREGATE");
  },
});
